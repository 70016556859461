@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Taviraj:wght@200;300;400;700&display=swap');

@import './colors.scss';

body  {
  font-family: 'Sarabun', sans-serif;
  font-size: 14px;
}

a {
  color: $primaryColor;

  &:hover, &:focus {
    color: darken($primaryColor, 15%);
  }
}

b, strong {
  font-weight: bold; 
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: initial;
}

button:focus {
  outline: none;
}

svg   {
  vertical-align: initial;
}

code {
  display: block;
  white-space: pre-wrap;
}

.main-sidebar {
  z-index: 1000;

  .nav  {
    .nav-item  {
      font-family: 'Sarabun', sans-serif;    

      &:hover  {
        background-color: #fff;
        box-shadow: none;
        color: inherit;
      }

      .nav-link {
        font-family: 'Sarabun', sans-serif;    
        padding: 0.9375rem 1rem;

        .menu-arrow  {
          transition: transform 0.2s;
          position: relative;
          right: -3px;
        }

        &.open .menu-arrow  {
          transform: rotate(180deg);
        }
      }
    }

    .nav-item.active, 
    .nav-item:hover {
      color: $primaryColor;

      i {
        color: $primaryColor;
      }
    }

    .nav-item .nav-link.active, 
    .nav-item .nav-link:hover {
      box-shadow: inset 0.1875rem 0 0 $primaryColor;
      color: $primaryColor;

      i {
        color: $primaryColor;
      }
    }
  }

  .navbar-collapse  {
    box-shadow: rgba(61, 81, 112, 0.2) 0px 0px 0.4375rem inset;

    .nav-item {
      border-top: 1px solid #eee;
    }
  }
}

.btn-primary  {
  background-color: $secondaryColor;
  border-color: $secondaryColor;

  &:focus {
    background-color: darken($secondaryColor, 7%);
    border-color: darken($secondaryColor, 7%);
  }

  &:hover {
    background-color: darken($secondaryColor, 7%);
    border-color: darken($secondaryColor, 7%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(96, 44, 163, 0.25);
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active, .show > .btn-primary.dropdown-toggle {
      background-color: darken($secondaryColor, 7%);
      border-color: darken($secondaryColor, 7%);
    }
  }

  &.disabled, &:disabled {
    background-color: $secondaryColor;
    border-color: $secondaryColor;
  }
}

.btn-danger  {
  background-color: $primaryColor;
  border-color: $primaryColor;

  &:focus {
    background-color: darken($primaryColor, 7%);
    border-color: darken($primaryColor, 7%);
  }

  &:hover {
    background-color: darken($primaryColor, 7%);
    border-color: darken($primaryColor, 7%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 4px 10px rgba(196, 24, 60, 0.25);
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active, .show > .btn-danger.dropdown-toggle {
      background-color: darken($primaryColor, 7%);
      border-color: darken($primaryColor, 7%);
    }
  }

  &.disabled, &:disabled {
    background-color: $primaryColor;
    border-color: $primaryColor;
  }
}

.btn-secondary {
  background-color: $darkColor;
  border-color: $darkColor;

  &:focus {
    background-color: darken($darkColor, 7%);
    border-color: darken($darkColor, 7%);
  }

  &:hover {
    background-color: darken($darkColor, 7%);
    border-color: darken($darkColor, 7%);
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active, .show > .btn-secondary.dropdown-toggle {
      background-color: darken($darkColor, 7%);
      border-color: darken($darkColor, 7%);
    }
  }

  &.disabled, &:disabled {
    background-color: $darkColor;
    border-color: $darkColor;
  }
}

.btn-info {
  background-color: #1890ff;
  border-color: #1890ff;

  &:focus {
    background-color: darken(#1890ff, 7%);
    border-color: darken(#1890ff, 7%);
  }

  &:hover {
    background-color: darken(#1890ff, 7%);
    border-color: darken(#1890ff, 7%);
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active, .show > .btn-secondary.dropdown-toggle {
      background-color: darken(#1890ff, 7%);
      border-color: darken(#1890ff, 7%);
    }
  }

  &.disabled, &:disabled {
    background-color: #1890ff;
    border-color: #1890ff;
  }
}

.btn-success {
  background-color: #60d394;
  border-color: #60d394;

  &:focus {
    background-color: darken(#60d394, 7%);
    border-color: darken(#60d394, 7%);
  }

  &:hover {
    background-color: darken(#60d394, 7%);
    border-color: darken(#60d394, 7%);
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active, .show > .btn-secondary.dropdown-toggle {
      background-color: darken(#60d394, 7%);
      border-color: darken(#60d394, 7%);
    }
  }

  &.disabled, &:disabled {
    background-color: #60d394;
    border-color: #60d394;
  }
}

.btn-white:not([disabled]):not(.disabled):active, 
.btn-white:not([disabled]):not(.disabled).active, 
.btn-black:not([disabled]):not(.disabled):active, 
.btn-black:not([disabled]):not(.disabled).active {
  background-color: $primaryColor;
  border-color: $primaryColor;

  + .btn {
    border-left-color: $primaryColor;
  }
}

.badge-primary {
  background-color: $primaryColor;
}

.custom-control-input:checked ~ .custom-control-label::before { 
  background-color: $primaryColor;
}

.font-11  {
  font-size: 11px;
}

.font-12  {
  font-size: 12px;
}

.pointer {
  cursor: pointer;
}

.text-primary, .text-danger {
  color: $primaryColor !important;
}

.text-light {
  color: #CACEDB !important;
}

.text-underline {
  text-decoration: underline;
}

.bg-primary {
 background-color: $primaryColor !important;
}

.bg-grey {
  background-color: #f2f2f2 !important;
 }

.custom-toggle  {
  margin-bottom: 0;
}

.custom-control { 
  min-height: 1.75rem;
}

i.material-icons {
  color: #CACEDB;
  top: 0;

  &:hover {
    color: inherit;
  }
}

.sidebar-hidden {
  .main-sidebar {
    left: -100%;
  }

  .main-content {
    margin-left: 0 !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.btn:focus  {
  outline: none;
  box-shadow: none;
}

.date-w-100 .react-datepicker-wrapper {
  flex: 1
}

.date-w-100 .react-datepicker__input-container  {
  width: 100%
}

.no-shadow:focus, .no-shadow:hover   {
  box-shadow: none !important;
}

.btn:not(.btn-xs):not(.btn-sm) { 
  padding: 0.719rem 1rem;
}

.card .table-bordered {
  border-width: 1px 0;

  td, th {
    &:first-child {
      border-left-width: 0
    }

    &:last-child {
      border-right-width: 0
    }
  }
}

.form-control {
  padding: 0.532rem 0.75rem;

  &:focus {
    border-color: $secondaryColor;

    &:hover {
      border-color: $secondaryColor;
    }
  }
}

.pagination {
  .page-item  {
    border-radius: 6px;

    &.active  {
      .page-link  {
        background-color: $primaryColor;
        border-color: $primaryColor;
      }
    }

    &:not(.active)  {
      .page-link, .page-link:hover { 
        color: #868e96;
      }
    }

    .page-link{
      border-radius: 6px;
    }

    + .page-item  {
      margin-left: 5px;
      border-radius: 6px;
    }
  }
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

select.form-control {
  height: auto !important;
}

.react-autosuggest__input {
  width: 100%;
  border: 1px solid #e1e5eb;
  border-radius: 0.25rem;
  line-height: 1.5;
  padding: 0.4375rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 300;
  color: #495057;
  transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.react-autosuggest__input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #007bff;
  box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container  {
  position: absolute;
  left: 5px;
  right: 5px;
  background-color: #fff;
  z-index: 101;
  overflow: auto;
  max-height: 250px;
}

.react-autosuggest__suggestions-container--open {
  border: 1px solid #e1e5eb;
  border-top: 0;
}

.react-autosuggest__suggestions-list  {
  list-style: none;
  padding: 5px 0;
  margin-bottom: 0;
}

.react-autosuggest__suggestion  {
  padding: 8px 15px;
  cursor: pointer;
  font-size: 0.8125rem;
  color: #495057;
}

.react-autosuggest__suggestion--highlighted  {
  background-color: #fbfbfb;
}

.swal2-styled {
  padding: 0.5625rem 1rem;
  font-size: 0.75rem !important;    
  min-width: 100px;
}

.swal2-cancel {
  background-color: $primaryColor !important;
  color: #fff !important;
}

.swal2-confirm {
  background-color: $secondaryColor !important;
  color: #fff !important;
}

.table thead th {  
  border-bottom-width: 1px;
}

.table thead {  
  background-color: $darkColor;
}

.table thead th {  
  border-color: $darkColor;
  color: #fff;
}

.table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

.modal  {
  overflow: auto;
}

.content-dropzone-upload .dropzone  {
  border: 2px dashed #e6e6e6;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-image-uploaded-item  {
  position: relative;
  list-style-type: none;
  width: calc(100% / 3);
  padding: 5px;
  text-align: center;
  z-index: 1100;

  > div {
    border: 1px solid #e6e6e6;
    padding: 15px 5px;
    border-radius: 6px;
  }
}

.content-loading-wrapper  {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.8);
    z-index: 100;
    display: none;
  }

  &.loading::before {
    display: block
  }
}

.chapter-content-wrapper {
  max-width: 901px;
  margin-left: auto;
  margin-right: auto;
  
  figure {
    margin-bottom: 0;
  }

  .ql-editor {
    font-size: 18px;
    color: #191919;
    
    img {
      max-width: 100%;
    }

    p {
      line-height: 2;
    }
  }

  .ql-container {
    height: 500px;
    resize: vertical;
  }

  .ql-container, .preview-container  {
    font-family: 'Sarabun', serif;
    font-size: 18px;
    color: #191919;
    padding: 0 12px;
  }

  .ql-color-picker  {
    position: relative;
    top: -4px;
  }

  .ql-color-picker  {
    position: relative;
    top: -4px;
  }
}

.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: $primaryColor;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: $primaryColor;
}

html .ql-toolbar button:hover .ql-stroke, html .ql-toolbar button:active .ql-stroke, html .ql-toolbar button:focus .ql-stroke {
  stroke: $primaryColor !important;
}

html .ql-toolbar button:hover .ql-fill, html .ql-toolbar button:hover .ql-stroke.ql-fill, html .ql-toolbar button:active .ql-fill, html .ql-toolbar button:active .ql-stroke.ql-fill, html .ql-toolbar button:focus .ql-fill, html .ql-toolbar button:focus .ql-stroke.ql-fill {
  fill: $primaryColor !important;
}

html .ql-toolbar .ql-picker .ql-picker-options { 
  padding: 6px 10px;
}

html .ql-toolbar .ql-picker .ql-picker-item:hover {
  color: #000 !important;
}

.ql-picker-label  {
  border: 0;
  
  &:focus {
    outline: none;
  }
}

.chapter-editor-wrapper {
  position: relative;

  .bem  {
    margin: 5px;
  }
  .ql-emojiblot .ap   {
    line-height: initial;
  }

  &.editor-block::before  {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: rgba(255,255,255,0.8)
  }
} 

.ql-color, .ql-background {
  .ql-picker-options [data-value=color-picker]:before {
    content: 'เลือกสีเพิ่มเติม';
    font-size: 12px;
  }
}

.ql-color, .ql-background {
  .ql-picker-options [data-value=color-picker] {
    width: 100% !important;
    background-color: #f5f5f5 !important;
    height: 30px !important;
    text-align: center;
    color: #000;
    border-radius: 6px;
    padding: 3px 5px;
    margin-top: 10px;

    &:focus {
      outline: none;
    }

    &:hover {
      border-color: transparent !important;
      color: #000 !important;
      background-color: #eee !important;
    }
  }
}

.ql-snow .ql-color-picker {
  &.ql-color, &.ql-background {
    .ql-picker-item {
      &:focus {
        outline: none;
      }
    
      &[data-value='#FFFFFF'] {
        border-color: #ddd;
      }
    }
  } 
  
  .ql-picker-item {
    width: 22px;
    height: 22px;
    border-radius: 3px;
  }
}

.ql-divider {
  position: relative;

  &::before{
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    height: 2px;
    width: 10px;
    background-color: #818EA3;
  }
}

.ql-content-divider {
  border-top: 1px solid #ddd;
  margin: 15px 0;
}

.modal-content-preview  {
  max-width: 1000px;
}

.modal-color-picker  {
  max-width: 250px;
}

.sketch-picker > div:last-child {
  > div {
    width: 20px !important;
    height: 20px !important;

    &:first-child > span > div {
      border: 1px solid #ddd;
    }

    > span > div {
      box-shadow: none !important;

      &[title='#FFFFFF']  {
        border: 1px solid #ddd;
      }
    }
  }
}

.pcr-app .pcr-interaction .pcr-type.active  {
  background: $primaryColor;
}

.ck-content {
  font-family: 'Sarabun', serif;
  font-size: 18px;
  color: #191919;
  letter-spacing: 0.5px;
  
  p {
    margin-bottom: 15px;
    line-height: 200%;
  }

  hr  {
    height: 2px;
  }

  video {
    width: 100%;
  }
}

.ck.ck-editor__editable_inline {
  padding: 0 11px;
}

.rc-time-picker.time-picker-block {
  display: block;

  .rc-time-picker-input {
    height: 38px;
  }
  
  .rc-time-picker-clear  {  
    top: 7px;
  }
}

.ant-btn  {
  height: auto;
}

.ant-picker:hover {
  border-color: #b3bdcc;
}

.ant-picker-focused  {
  box-shadow: none;
  border-color: $secondaryColor !important;
}

.ant-picker-range .ant-picker-active-bar {
  background-color: $primaryColor;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: $primaryColor;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: #FEE9EF;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background :#FEE9EF;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $primaryColor;
}

.channel-summary  {
  .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, 
  .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content  {
    padding: 5px;
  }
}

.chat-character-card {
  position: relative;
  min-width: 200px;
  min-height: 66px;

  &.add-char-btn  {
    min-width: 0;
    width: 66px;
  }

  &.active  {
    background-color: #f5f5f5;

    &::before {
      content: 'ตัวละครหลัก';
      position: absolute;
      top: -12px;
      left: 2px;
      background-color: #fa2560;
      color: #fff;
      font-size: 11px;
      border-radius: 4px;
      padding: 1px 10px;
    }
  }

  .edit-options {
    position: absolute;
    top: 0;
    right: 10px;
    width: 20px;
    height: 20px;
    text-align: right;
    cursor: pointer;
  }

  .ant-skeleton {
    width: auto;
    display: block;
  }

  .ant-skeleton-element .ant-skeleton-avatar  {
    width: 50px;
    height: 50px;
  }

  .ant-skeleton-element .ant-skeleton-button-sm {
    height: 12px;
    line-height: 12px;
    display: block;
  }
}

.chat-preview  {
  background-color: #f2f2f2;
  padding: 50px 20px;
  border-radius: 10px;

  .chat-container  {
    background-color: #fff;
    border-radius: 20px;
    width: 500px;
    display: flex;
    flex-direction: column;
  }

  .chat-message-group { 
    position: relative;
    height: 500px;
    overflow: auto;
    padding: 20px 30px;

    &.message-loading {
      opacity: 0.3;
    }

    &.message-edit-mode {
      .chat-message-item  {
        opacity: 0.3;
      }
    }
  }
  
  .chat-toolbar  {
    border-top: 1px solid #f2f2f2;

    .chat-toolbar-button-group  {
      border-left: 1px solid #f2f2f2;
    }

    .border-left  {
      border-left-color: #f2f2f2 !important;
    }

    .chat-toolbar-button  {
      position: relative;
      padding: 15px 20px;
      cursor: pointer;

      &.first-item  {
        border-bottom-left-radius: 20px;
      }

      &.last-item  {
        border-bottom-right-radius: 20px;
      }

      &:hover {
        background-color: #f9f9f9;
      }

      &.sound-disabled  {
        color: #bbb;

        &:before {
          content: '';
          position: absolute;
          top: 20px;
          left: 20px;
          width: 1px;
          background-color: #999;
          height: 25px;
          transform: rotate(45deg) translate(-50%, -50%);

          @media(max-width: 767px)  {
            left: 10px;
          }
        }
      }

      + .chat-toolbar-button  {
        border-left: 1px solid #f2f2f2;
      }

      &.border-right  {
        border-color: #f2f2f2 !important;
      }
    }
  }

  .chat-footer  {
    padding: 10px 0;
    border-top: 1px solid #f2f2f2;

    > div {
      padding-left: 20px;
      padding-right: 20px;
    }

    .chat-more-option {
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #f2f2f2;
      
      .ant-switch {
        background-color: #fa2560;
      }
  
      .ant-switch-checked {
        background-color: #602ca3;
      }
    }
  }

  .chat-message-item  {
    display: flex;

    + .chat-message-item  {
      margin-top: 15px;
    }

    &.system-message  {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &.item-reverse  {
      flex-direction: row-reverse;

      .message-avatar {
        margin-left: 10px;
        margin-right: 0;
      }

      .message-name {
        text-align: right;
      }

      .message-bubble {
        border-radius: 15px 0 15px 15px;
        background-color: #f6d2e2;
      }

      .message-content  {
        order: 2;
      }

      .message-options  {
        order: 1;
      }

      .ant-skeleton-element .ant-skeleton-button-lg { 
        border-radius: 15px 0 15px 15px;
      }
    }

    &.item-editing  {
      opacity: 1 !important;
    }

    .ant-skeleton-element .ant-skeleton-avatar  {
      width: 50px;
      height: 50px;
    }

    .ant-skeleton-element .ant-skeleton-button-sm {
      height: 15px;
      line-height: 15px;
    }

    .ant-skeleton-element .ant-skeleton-button-lg {
      width: 150px;
      border-radius: 0 15px 15px;
    }
  }

  .message-content  {
    cursor: pointer;
  }

  .message-phone  {
    width: 170px;
    
    .message-phone-icon {
      width: 50px;
      height: 50px;
      background-color: #60d394;
      color: #fff;
      border-radius: 50em;
      display: flex;
      align-items: center;
      justify-content: center;

      &.phone-cancel {
        background-color: #fa2560;
      }
  
      &.phone-miss {
        background-color: #646E78;
      }
    }
  }

  .message-video  {
    border-radius: 6px;
    max-width: 200px;

    img {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .bg-grey  {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .message-video-desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
  }

  .phone-cancel  {
    .anticon-phone  {
      transform: rotate(90deg);
      display: block;
      padding-top: 5px;
    }
  }

  .phone-miss  {
    .anticon-phone  {
      transform: rotate(-135deg);
      display: block;
    }
  }

  .message-avatar {
    min-width: 50px;
    margin-right: 10px;
  }

  .message-bubble {
    background-color: #f5f5f5;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-word;
    border-radius: 0 15px 15px;
  }

  .message-options  {
    cursor: pointer;
    display: flex;
    margin-left: 5px;
    align-items: center;
  }

  .input-chat   {
    position: relative;
    
    .input-icon {
      position: absolute;
      left: 15px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 18px;
      color:#ccc;
    }

    &:not(.no-image) .form-control {
      padding-left: 70px;
    }

    &.no-image {
      .input-icon  {
        display: none;
      }
    }
  }

  .form-control {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    box-shadow: none;

    &.input-focus {
      border-color: #fa2560;
    }
  }

  .btn-chat  {
    display: flex !important;
    justify-content: center;
    align-items: center;
    min-width: 38px;
    height: 38px;
    font-size:16px;
    padding: 0 !important;

    button {
      border: 0;
      background-color: transparent;
    }
  }

  .system-message-badge {
    display: inline-flex;
    background-color: #646E78;
    color: #fff;
    padding: 3px 15px;
    font-size: 11px;
  }

  .insert-message-block {
    padding: 10px 30px;
    border-top: 1px solid #f2f2f2;
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #b3bdcc;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 38px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 36px;
}

.ant-upload {
  .anticon  {
    display: block;
  }
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #000;
}

.ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-nav .ant-tabs-tab-active:hover  {
  color: #fa2560;
}

.ant-tabs-nav .ant-tabs-tab-disabled, .ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: rgba(#000, 0.25);
}

.ant-tabs-ink-bar {
  background-color: #fa2560;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  background: #fa2560;
  border-color: #fa2560;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: lighten(#fa2560, 5%);
}

.ant-radio-button-wrapper:hover {
  color: #fa2560;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #fa2560;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: lighten(#fa2560, 5%);
  border-color: lighten(#fa2560, 5%);
}

.message-option-group {
  border-top: 1px solid #f2f2f2;
  margin-top: 10px;

  .message-special-btn  {
    position: relative;
    transition: opacity 0.2s;

    &.option-fade {
      opacity: 0.3;
    }
  }
}

.thumbnail-preview {
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.thumbnail-preview-area {
  width: 150px;
  min-width: 150px;
}

.image-placeholder {
  position: relative;
  background-color: #f2f2f2;
}

::-webkit-file-upload-button {
  cursor:pointer;
}

.input-file-hidden {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.chapter-thumbnail-container  {
  width: 150px;
  min-width: 150px;
  margin-right: 40px;
  margin-top: 20px;

  @media(max-width: 991px)  {
    margin-left: auto;
    margin-right: auto;
  }
}

.book-preview-area {
  width: 200px;
  min-width: 200px;
}

.book-preview {
  width: 200px;
  height: 283px;
  overflow: hidden;
}

.book-landscape-preview {
  width: 200px;
  height: 105px;
  overflow: hidden;
}

.book-cover-preview {
  width: 200px;
  height: 43px;
  overflow: hidden;
}

.content-dark-mode .ck-content {
  background-color: #212529 !important;
  color: #ddd;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.popover-body-p-0 .ant-popover-inner-content {
  padding: 0;
}

.modal-body-p-0 .ant-modal-body {
  padding: 0;
}

aside.emoji-picker-react {
  box-shadow: none !important;
}

.ant-pagination-item-active {
  border-color: $primaryColor;
}

.ant-pagination-item-active:focus, .ant-pagination-item-active:hover {
  border-color: $primaryColor;
}

.ant-pagination-item:focus, .ant-pagination-item:hover {
  border-color: $primaryColor;

  a {
    color: $primaryColor;
  }
}

.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
  color: $primaryColor;
}

.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  color: $primaryColor;
  border-color: $primaryColor;
}

.row-dragging {
  background-color: #f5f7f9;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.16);

  > td {
    padding: 16px 20px;
  }
}

.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover {
  color: $primaryColor;
}

.ant-skeleton-element .ant-skeleton-button-sm {
  height: 15px;
  line-height: 15px;
}

.ant-picker-input > input {
  height: 30px;
}

.attachment-description .ant-descriptions-item-label {
  width: 200px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #fa2560;
}

.ant-radio-inner::after {
  background-color: #fa2560;
}

.ant-radio-checked::after {
  border-color: #fa2560;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: #fa2560;
}

.ant-table-thead > tr > th {
  background-color: $darkColor;
  color: #fff;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: darken($darkColor, 5%);
}

.ant-table-thead th.ant-table-column-sort {
  background: darken($darkColor, 10%);
}

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
  color: #fa2560;
}